<template>
  <div class="mypaper">
    <el-form ref="form" :model="paperForm" label-width="100px" label-suffix="：" :inline="true">
      <el-form-item label="试卷状态">
        <span 
          class="status-btn"
          :class="{active: item.id === paperForm.status}" 
          v-for="item in paperStatus"
          :key="item.id"
          @click="handleClickStatus(item.id)"
        >{{item.title}}</span>
      </el-form-item>
      <el-row>
        <el-form-item label="创建日期">
          <el-date-picker
            v-model="paperForm.create_time_start"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="试卷类型">
          <el-select v-model="paperForm.report_type" placeholder="请选择试卷类型">
            <el-option label="全部" value="3,4"></el-option>
            <el-option label="错题巩固" value="3"></el-option>
            <el-option label="强化提高" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" class="search-btn" @click="queryList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <div v-loading="loading">
      <div class="paper-item" v-for="(item, index) in jobList" :class="{isPad: app.isPad}" :key="index">
        <div class="fr item-header">
          <div class="fr item-header-left">
            <span class="header-left-title">{{item.title}}</span>
            <span class="header-left-grade">{{item.grade_name}}</span>
          </div>
        </div>
        <div class="item-time">{{item.created_at}}</div>
        <div class="item-footer fr">
          <div class="item-footer-left fr">
            <div class="footer-left-type">类型：<span class="item-footer-type">{{item.report_type == 3 ? '错题巩固' : '强化提高'}}</span></div>
            <!-- <div class="fr">
              难度：<Star :val="item.difficulty" />
            </div> -->
            <div class="gl-paper">关联试卷：<span class="item-footer-type">{{item.relation_report_title}}</span></div>
          </div>
          <div class="item-footer-right">
            <span class="item-right-btn tc" @click="startTest(item)" v-if="item.is_submit === 0">开始测试</span>
            <span class="item-right-btn tc" @click="paperFenxi(item)" v-else>试卷分析</span>
          </div>
        </div>
      </div>
      <pagination
        :total="total"
        :page.sync="current_page"
        :size.sync="page_size"
        @handleQueryList="queryList"
      />
    </div>
  </div>
</template>

<script>
import Star from 'comps/star'
import Pagination from 'comps/pagination'

import { ERROR_CODE, PAPER_STATUS_LIST } from 'utils/config'

import {paperList} from 'api'

export default {
  data() {
    return {
      paperForm: {
        // 试卷状态
        status: null,
        // 创建日期
        create_time_start: [],
        // 试卷类型
        report_type: '3,4',
      },
      // 作业列表
      jobList: [],
      total: 0,
      loading: false,
      current_page: 1,
      page_size: 10,
      // 试卷状态
      paperStatus: PAPER_STATUS_LIST.filter(v => v.id !== 4),
    }
  },
  components: {
    Star,
    Pagination,
  },
  created () {
    this.copyPaperForm = {...this.paperForm}
    this.queryList()
  },
  inject: ['app'],
  methods: {
    // 选择状态
    handleClickStatus(id) {
      this.paperForm.status = id
      this.queryList()
    },
    // 重置
    reset() {
      this.paperForm = {...this.copyPaperForm}
      this.queryList()
    },
    // 试卷分析
    paperFenxi({report_id}) {
      this.$router.push({
        path: '/paperfenxi',
        query: {
          id: report_id
        }
      })
    },
    // 获取练习列表
    async queryList() {
      this.loading = true
      let {create_time_start, ...args} = this.paperForm
      if (create_time_start && create_time_start.length !== 0) {
        args.create_time_start = create_time_start[0]
        args.create_time_end = create_time_start[1]
      }
      let {error, data} = await paperList({
        current_page: 1,
        page_size: 10,
        ...args
      })
      this.loading = false
      if (error === ERROR_CODE) {
        this.jobList = data.list || []
        this.total = data.total_items
      }
    },
    // 开始测试
    startTest({report_id, difficulty}) {
      this.$router.push({
        path: '/starttest',
        query: {
          id: report_id, 
          diff: difficulty
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.mypaper {
  .el-form {
    margin-top: 40px;
    margin-bottom: 20px;
    .status-btn {
      padding: 8px 10px;
      border-radius: 6px;
      color: #555;
      font-size: 16px;
      background: transparent;
      cursor: pointer;
      margin-right: 20px;
      &.active {
        background: #E9E9E9;
        color: #333;
      }
    }
    .search-btn {
      background: #68B8E6;
      border-color: #68B8E6;
      &:active {
        background:#5db4e6;
        outline: 0;
      }
    }
  }
  .paper-item {
    background: white;
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 12px;
    &.isPad {
      .header-left-title {
        font-size: 20px !important;
      }
      .item-header-status {
        font-size: 14px !important;
      }
      .item-footer {
        font-size: 16px !important;
      }
      .footer-left-type {
        margin-right: 0 !important;
      }
      .item-middle-desc {
        margin-right: 0 !important;
      }
      .item-footer-left {
        flex-grow: 1;
        margin-right: 15px;
        justify-content: space-between;
      }
    }
    .item-header {
      justify-content: space-between;
      align-items: center;
      .item-header-left {
        align-items: center;
        .header-left-title {
          color: #333;
          font-size: 22px;
          margin-right: 35px;
        }
        .header-left-grade {
          color: #5DAEE6;
          font-size: 16px;
        }
      }
      .item-header-status {
        color: #E24D54;
        font-size: 16px;
        &.valid {
          color: gray;
        }
      }
    }
    .item-time {
      margin-top: 9px;
      font-size: 14px;
      color: #999;
    }
    .item-footer {
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #999;
      margin-top: 12px;
      .item-footer-left {
        align-items: center;
        .footer-left-type {
          margin-right: 80px;
          align-items: center;
        }
        .item-footer-type {
          color: #333;
        }
        .gl-paper {
          // margin-left: 135px;
        }
      }
      .item-footer-right {
        position: relative;
        .item-right-btn {
          width: 98px;
          height: 30px;
          line-height: 30px;
          background: #5DAEE6;
          display: inline-block;
          color: white;
          border-radius: 16px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>